import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <a id="combat"></a><h2>Combat</h2>
    <a id="special-attacks"></a><h3>SPECIAL ATTACKS</h3>
    <table border="0" cellPadding="0" cellSpacing="0" style={{
      "width": "100%"
    }}>
      <tbody>
        <tr>
          <td className="line"> </td>
        </tr>
      </tbody>
    </table>
    <a id="table-special-attacks"></a><p><b>Table: Special Attacks</b></p>
    <table border="0" cellPadding="1" cellSpacing="0">
      <tbody>
        <tr>
          <th style={{
            "width": "175px"
          }}>Special Attack</th>
          <th>Brief Description</th>
        </tr>
        <tr className="odd-row">
          <td><a style={{
              "color": "black"
            }} href="#aid-another">Aid another</a></td>
          <td>Grant an ally a +2 bonus on attacks or AC</td>
        </tr>
        <tr>
          <td><a style={{
              "color": "black"
            }} href="#bull-rush">Bull rush</a></td>
          <td>Push an opponent back 5 feet or more</td>
        </tr>
        <tr className="odd-row">
          <td><a style={{
              "color": "black"
            }} href="#charge">Charge</a></td>
          <td>Move up to twice your speed and attack with +2 bonus</td>
        </tr>
        <tr>
          <td><a style={{
              "color": "black"
            }} href="#disarm">Disarm</a></td>
          <td>Knock a weapon from your opponent&rsquo;s hands</td>
        </tr>
        <tr className="odd-row">
          <td><a style={{
              "color": "black"
            }} href="#feint">Feint</a></td>
          <td>Negate your opponent&rsquo;s Dex bonus to AC</td>
        </tr>
        <tr>
          <td><a style={{
              "color": "black"
            }} href="#grapple">Grapple</a></td>
          <td>Wrestle with an opponent</td>
        </tr>
        <tr className="odd-row">
          <td><a style={{
              "color": "black"
            }} href="#mounted-combat">Mounted Combat</a></td>
          <td>Fight while riding a mount.</td>
        </tr>
        <tr>
          <td><a style={{
              "color": "black"
            }} href="#overrun">Overrun</a></td>
          <td>Plow past or over an opponent as you move</td>
        </tr>
        <tr className="odd-row">
          <td><a style={{
              "color": "black"
            }} href="#sunder">Sunder</a></td>
          <td>Strike an opponent&rsquo;s weapon or shield</td>
        </tr>
        <tr>
          <td><a style={{
              "color": "black"
            }} href="#throw">Throw splash weapon</a></td>
          <td>Throw container of dangerous liquid at target</td>
        </tr>
        <tr className="odd-row">
          <td><a style={{
              "color": "black"
            }} href="#trip">Trip</a></td>
          <td>Trip an opponent</td>
        </tr>
        <tr>
          <td><a style={{
              "color": "black"
            }} href="#turn">Turn (rebuke) undead</a></td>
          <td>Channel positive (or negative) energy to turn away (or
awe) undead &nbsp;</td>
        </tr>
        <tr className="odd-row">
          <td className="last-row"><a style={{
              "color": "black"
            }} href="#two-weapon-fighting">Two-weapon fighting</a></td>
          <td className="last-row">Fight with a weapon in each hand</td>
        </tr>
      </tbody>
    </table>
    <a id="aid-another"></a><h5>AID ANOTHER</h5>
    <p className="initial">In melee combat, you can help a friend attack
or defend by distracting or interfering with an opponent. If
you&rsquo;re in
position to make a melee attack on an opponent that is engaging a
friend in melee combat, you can attempt to aid your friend as a
standard action. You make an attack roll against AC 10. If you succeed,
your friend gains either a +2 bonus on his next attack roll against
that opponent or a +2 bonus to AC against that opponent&rsquo;s next
attack
(your choice), as long as that attack comes before the beginning of
your next turn. Multiple characters can aid the same friend, and
similar bonuses stack.</p>
    <p>You can also use this standard action to help a friend in
other ways, such as when he is affected by a spell, or to assist
another character&rsquo;s skill check.</p>
    <h5><a id="bull-rush">BULL RUSH</a></h5>
    <p className="initial">You can make a bull rush as a standard action
(an attack) or as part of a charge (see <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="#charge">Charge</a>,
below). When you make a bull rush, you attempt to push an opponent
straight back instead of damaging him. You can only bull rush an
opponent who is one size category larger than you, the same size, or
smaller.</p>
    <a id="initiating-a-bull-rush"></a><p><b>Initiating a Bull Rush</b>: First, you move into the
defender&rsquo;s space. Doing this provokes an attack of opportunity
from
each opponent that threatens you, including the defender. (If you have
the Improved Bull Rush feat, you don&rsquo;t provoke an attack of
opportunity
from the defender.) Any attack of opportunity made by anyone other than
the defender against you during a bull rush has a 25% chance of
accidentally targeting the defender instead, and any attack of
opportunity by anyone other than you against the defender likewise has
a 25% chance of accidentally targeting you. (When someone makes an
attack of opportunity, make the attack roll and then roll to see
whether the attack went astray.) </p>
    <p>Second, you and the defender make opposed Strength checks. You
each add a +4 bonus for each size category you are larger than Medium
or a &ndash;4 penalty for each size category you are smaller than
Medium. You
get a +2 bonus if you are charging. The defender gets a +4 bonus if he
has more than two legs or is otherwise exceptionally stable.</p>
    <a id="bull-rush-results"></a><p><b>Bull Rush Results</b>: If you beat the defender&rsquo;s
Strength
check result, you push him back 5 feet. If you wish to move with the
defender, you can push him back an additional 5 feet for each 5 points
by which your check result is greater than the defender&rsquo;s check
result.
You can&rsquo;t, however, exceed your normal movement limit. (Note: The
defender provokes attacks of opportunity if he is moved. So do you, if
you move with him. The two of you do not provoke attacks of opportunity
from each other, however.)</p>
    <p>If you fail to beat the defender&rsquo;s Strength check
result, you
move 5 feet straight back to where you were before you moved into his
space. If that space is occupied, you fall prone in that space.</p>
    <h5><a id="charge">CHARGE</a></h5>
    <p className="initial">Charging is a special full-round action that
allows you to move up to twice your speed and attack during the action.
However, it carries tight restrictions on how you can move.</p>
    <a id="movement-during-a-charge"></a><p><b>Movement During a Charge</b>: You must move before your
attack, not after. You must move at least 10 feet (2 squares) and may
move up to double your speed directly toward the designated opponent.
You must have a clear path toward the opponent, and nothing can hinder
your movement (such as difficult terrain or obstacles). Here&rsquo;s
what it
means to have a clear path. First, you must move to the closest space
from which you can attack the opponent. (If this space is occupied or
otherwise blocked, you can&rsquo;t charge.) Second, if any line from
your
starting space to the ending space passes through a square that blocks
movement, slows movement, or contains a creature (even an ally), you
can&rsquo;t charge. (Helpless creatures don&rsquo;t stop a charge.)</p>
    <p>If you don&rsquo;t have line of sight to the opponent at the
start
of your turn, you can&rsquo;t charge that opponent.</p>
    <p>You can&rsquo;t take a 5-foot step in the same round as a
charge.</p>
    <p>If you are able to take only a standard action or a move
action on your turn, you can still charge, but you are only allowed to
move up to your speed (instead of up to double your speed). You
can&rsquo;t
use this option unless you are restricted to taking only a standard
action or move action on your turn.</p>
    <a id="attacking-on-a-charge"></a><p><b>Attacking on a Charge</b>: After moving, you may make a
single melee attack. You get a +2 bonus on the attack roll. and take a
&ndash;2 penalty to your AC until the start of your next turn.</p>
    <p>A charging character gets a +2 bonus on the Strength check
made to bull rush an opponent (see <a href="#bull-rush">Bull
Rush</a>, above).</p>
    <p>Even if you have extra attacks, such as from having a high
enough base attack bonus or from using multiple weapons, you only get
to make one attack during a charge.</p>
    <a id="lances-and-charge-attacks"></a><p><b>Lances and Charge Attacks</b>: A lance deals double damage
if employed by a mounted character in a charge.</p>
    <p>Weapons Readied against a Charge: Spears, tridents, and
certain other piercing weapons deal double damage when readied (set)
and used against a charging character.</p>
    <h5><a id="disarm">DISARM</a></h5>
    <p className="initial">As a melee attack, you may attempt to disarm
your opponent. If you do so with a weapon, you knock the
opponent&rsquo;s
weapon out of his hands and to the ground. If you attempt the disarm
while unarmed, you end up with the weapon in your hand.</p>
    <p>If you&rsquo;re attempting to disarm a melee weapon, follow
the
steps outlined here. If the item you are attempting to disarm
isn&rsquo;t a
melee weapon the defender may still oppose you with an attack roll, but
takes a penalty and can&rsquo;t attempt to disarm you in return if your
attempt fails.</p>
    <a id="disarm-attack-of-opportunity"></a><p><b>Step 1: Attack of Opportunity</b>. You provoke an attack of
opportunity from the target you are trying to disarm. (If you have the
Improved Disarm feat, you don&rsquo;t incur an attack of opportunity
for
making a disarm attempt.) If the defender&rsquo;s attack of opportunity
deals
any damage, your disarm attempt fails.</p>
    <a id="disarm-opposed-rolls"></a><p><b>Step 2: Opposed Rolls</b>. You and the defender make
opposed attack rolls with your respective weapons. The wielder of a
two-handed weapon on a disarm attempt gets a +4 bonus on this roll, and
the wielder of a light weapon takes a &ndash;4 penalty. (An unarmed
strike is
considered a light weapon, so you always take a penalty when trying to
disarm an opponent by using an unarmed strike.) If the combatants are
of different sizes, the larger combatant gets a bonus on the attack
roll of +4 per difference in size category. If the targeted item
isn&rsquo;t
a melee weapon, the defender takes a &ndash;4 penalty on the roll.</p>
    <a id="disarm-consequences"></a><p><b>Step Three: Consequences</b>. If you beat the defender, the
defender is disarmed. If you attempted the disarm action unarmed, you
now have the weapon. If you were armed, the defender&rsquo;s weapon is
on the
ground in the defender&rsquo;s square.</p>
    <p>If you fail on the disarm attempt, the defender may
immediately react and attempt to disarm you with the same sort of
opposed melee attack roll. His attempt does not provoke an attack of
opportunity from you. If he fails his disarm attempt, you do not
subsequently get a free disarm attempt against him.</p>
    <p><i>Note</i>: A defender wearing spiked gauntlets can&rsquo;t
be
disarmed. A defender using a weapon attached to a locked gauntlet gets
a +10 bonus to resist being disarmed.</p>
    <a id="grabbing-items"></a><h6>Grabbing Items</h6>
    <p className="initial">You can use a disarm action to snatch an item
worn by the target. If you want to have the item in your hand, the
disarm must be made as an unarmed attack. If the item is poorly secured
or otherwise easy to snatch or cut away the attacker gets a +4 bonus.
Unlike on a normal disarm attempt, failing the attempt doesn&rsquo;t
allow
the defender to attempt to disarm you. This otherwise functions
identically to a disarm attempt, as noted above.</p>
    <p>You can&rsquo;t snatch an item that is well secured unless you
have
pinned the wearer (see <a href="#grapple">Grapple</a>). Even then, the
defender gains a +4 bonus on his roll to resist the attempt.</p>
    <h5><a id="feint"></a>FEINT</h5>
    <p className="initial">Feinting is a standard action. To feint, make
a Bluff check opposed by a Sense Motive check by your target. The
target may add his base attack bonus to this Sense Motive check. If
your Bluff check result exceeds your target&rsquo;s Sense Motive check
result, the next melee attack you make against the target does not
allow him to use his Dexterity bonus to AC (if any). This attack must
be made on or before your next turn.</p>
    <p>When feinting in this way against a nonhumanoid you take a
&ndash;4
penalty. Against a creature of animal Intelligence (1 or 2), you take a
&ndash;8 penalty. Against a nonintelligent creature, it&rsquo;s
impossible.</p>
    <p>Feinting in combat does not provoke attacks of opportunity.</p>
    <a id="feinting-as-a-move-action"></a><p><b>Feinting as a Move Action</b>: With the Improved Feint
feat, you can attempt a feint as a move action instead of as a standard
action.</p>
    <h5><a id="grapple">GRAPPLE</a></h5>
    <a id="grapple-checks"></a><h6>Grapple Checks</h6>
    <p className="initial">Repeatedly in a grapple, you need to make
opposed grapple checks against an opponent. A grapple check is like a
melee attack roll. Your attack bonus on a grapple check is:</p>
    <dl>
      <dd>
        <p><b>Base attack bonus + Strength modifier + special size
modifier</b></p>
      </dd>
    </dl>
    <a id="grapple-special-size-modifier"></a><p><b>Special Size Modifier</b>: The special size modifier for a
grapple check is as follows: Colossal +16, Gargantuan +12, Huge +8,
Large +4, Medium +0, Small &ndash;4, Tiny &ndash;8, Diminutive
&ndash;12, Fine &ndash;16. Use
this number in place of the normal size modifier you use when making an
attack roll.</p>
    <a id="starting-a-grapple"></a><h6>Starting a Grapple</h6>
    <p className="initial">To start a grapple, you need to grab and hold
your target. Starting a grapple requires a successful melee attack
roll. If you get multiple attacks, you can attempt to start a grapple
multiple times (at successively lower base attack bonuses).</p>
    <a id="grapple-attack-of-opportunity"></a><p><b>Step 1: Attack of Opportunity</b>. You provoke an attack of
opportunity from the target you are trying to grapple. If the attack of
opportunity deals damage, the grapple attempt fails. (Certain monsters
do not provoke attacks of opportunity when they attempt to grapple, nor
do characters with the Improved Grapple feat.) If the attack of
opportunity misses or fails to deal damage, proceed to Step 2.</p>
    <a id="grapple-grab"></a><p><b>Step 2: Grab</b>. You make a melee touch attack to grab the
target. If you fail to hit the target, the grapple attempt fails. If
you succeed, proceed to Step 3.</p>
    <a id="grapple-hold"></a><p><b>Step 3: Hold</b>. Make an opposed grapple check as a free
action. If you succeed, you and your target are now grappling, and you
deal damage to the target as if with an unarmed strike.</p>
    <p>If you lose, you fail to start the grapple. You automatically
lose an attempt to hold if the target is two or more size categories
larger than you are.</p>
    <p>In case of a tie, the combatant with the higher grapple check
modifier wins. If this is a tie, roll again to break the tie.</p>
    <a id="maintain-grapple"></a><p><b>Step 4: Maintain Grapple</b>. To maintain the grapple for
later rounds, you must move into the target&rsquo;s space. (This
movement is
free and doesn&rsquo;t count as part of your movement in the round.)
Moving,
as normal, provokes attacks of opportunity from threatening opponents,
but not from your target.</p>
    <p>If you can&rsquo;t move into your target&rsquo;s space, you
can&rsquo;t maintain
the grapple and must immediately let go of the target. To grapple
again, you must begin at Step 1.</p>
    <a id="grappling-consequences"></a><h6>Grappling Consequences</h6>
    <p className="initial">While you&rsquo;re grappling, your ability to
attack
others and defend yourself is limited.</p>
    <a id="grapple-no-threatened-squares"></a><p><b>No Threatened Squares</b>: You don&rsquo;t threaten any
squares
while grappling.</p>
    <a id="grapple-no-dexterity-bonus"></a><p><b>No Dexterity Bonus</b>: You lose your Dexterity bonus to AC
(if you have one) against opponents you aren&rsquo;t grappling. (You
can
still use it against opponents you are grappling.)</p>
    <a id="grapple-no-movement"></a><p><b>No Movement</b>: You can&rsquo;t move normally while
grappling.
You may, however, make an opposed grapple check (see below) to move
while grappling.</p>
    <a id="if-youre-grappling"></a><h6>If You&rsquo;re Grappling</h6>
    <p className="initial">When you are grappling (regardless of who
started the grapple), you can perform any of the following actions.
Some of these actions take the place of an attack (rather than being a
standard action or a move action). If your base attack bonus allows you
multiple attacks, you can attempt one of these actions in place of each
of your attacks, but at successively lower base attack bonuses.</p>
    <a id="grapple-activate-a-magic-item"></a><p><b>Activate a Magic Item</b>: You can activate a magic item,
as long as the item doesn&rsquo;t require a spell completion trigger.
You
don&rsquo;t need to make a grapple check to activate the item.</p>
    <a id="grapple-attack-your-opponent"></a><p><b>Attack Your Opponent</b>: You can make an attack with an
unarmed strike, natural weapon, or light weapon against another
character you are grappling. You take a &ndash;4 penalty on such
attacks. You
can&rsquo;t attack with two weapons while grappling, even if both are
light
weapons.</p>
    <a id="grapple-cast-a-spell"></a><p><b>Cast a Spell</b>: You can attempt to cast a spell while
grappling or even while pinned (see below), provided its casting time
is no more than 1 standard action, it has no somatic component, and you
have in hand any material components or focuses you might need. Any
spell that requires precise and careful action is impossible to cast
while grappling or being pinned. If the spell is one that you can cast
while grappling, you must make a Concentration check (DC 20 + spell
level) or lose the spell. You don&rsquo;t have to make a successful
grapple
check to cast the spell.</p>
    <a id="grapple-damage-your-opponent"></a><p><b>Damage Your Opponent</b>: While grappling, you can deal
damage to your opponent equivalent to an unarmed strike. Make an
opposed grapple check in place of an attack. If you win, you deal
nonlethal damage as normal for your unarmed strike (1d3 points for
Medium attackers or 1d2 points for Small attackers, plus Strength
modifiers). If you want to deal lethal damage, you take a &ndash;4
penalty on
your grapple check.</p>
    <p><i>Exception</i>: Monks deal more damage on an unarmed strike
than other characters, and the damage is lethal. However, they can
choose to deal their damage as nonlethal damage when grappling without
taking the usual &ndash;4 penalty for changing lethal damage to
nonlethal
damage.</p>
    <a id="grapple-draw-a-light-weapon"></a><p><b>Draw a Light Weapon</b>: You can draw a light weapon as a
move action with a successful grapple check.</p>
    <a id="escape-from-grapple"></a><p><b>Escape from Grapple</b>: You can escape a grapple by
winning an opposed grapple check in place of making an attack. You can
make an Escape Artist check in place of your grapple check if you so
desire, but this requires a standard action. If more than one opponent
is grappling you, your grapple check result has to beat all their
individual check results to escape. (Opponents don&rsquo;t have to try
to
hold you if they don&rsquo;t want to.) If you escape, you finish the
action
by moving into any space adjacent to your opponent(s).</p>
    <a id="grapple-move"></a><p><b>Move</b>: You can move half your speed (bringing all others
engaged in the grapple with you) by winning an opposed grapple check.
This requires a standard action, and you must beat all the other
individual check results to move the grapple.</p>
    <p><i>Note</i>: You get a +4 bonus on your grapple check to move
a pinned opponent, but only if no one else is involved in the grapple.</p>
    <a id="grapple-retrieve-spell-component"></a><p><b>Retrieve a Spell Component</b>: You can produce a spell
component from your pouch while grappling by using a full-round action.
Doing so does not require a successful grapple check.</p>
    <a id="grapple-pin-your-opponent"></a><p><b>Pin Your Opponent</b>: You can hold your opponent immobile
for 1 round by winning an opposed grapple check (made in place of an
attack). Once you have an opponent pinned, you have a few options
available to you (see below).</p>
    <a id="grapple-break-anothers-pin"></a><p><b>Break Another&rsquo;s Pin</b>: If you are grappling an
opponent
who has another character pinned, you can make an opposed grapple check
in place of an attack. If you win, you break the hold that the opponent
has over the other character. The character is still grappling, but is
no longer pinned.</p>
    <a id="grapple-use-opponents-weapon"></a><p><b>Use Opponent&rsquo;s Weapon</b>: If your opponent is
holding a
light weapon, you can use it to attack him. Make an opposed grapple
check (in place of an attack). If you win, make an attack roll with the
weapon with a &ndash;4 penalty (doing this doesn&rsquo;t require
another action).
You don&rsquo;t gain possession of the weapon by performing this action.</p>
    <a id="if-youre-pinning-an-opponent"></a><h6>If You&rsquo;re Pinning an Opponent</h6>
    <p className="initial">You can attempt to damage your opponent with
an opposed grapple check, you can attempt to use your opponent&rsquo;s
weapon
against him, or you can attempt to move the grapple (all described
above). At your option, you can prevent a pinned opponent from speaking.</p>
    <p>You can use a disarm action to remove or grab away a well
secured object worn by a pinned opponent, but he gets a +4 bonus on his
roll to resist your attempt (see <a href="#disarm">Disarm</a>).</p>
    <p>You may voluntarily release a pinned character as a free
action; if you do so, you are no longer considered to be grappling that
character (and vice versa).</p>
    <p>You can&rsquo;t draw or use a weapon (against the pinned
character
or any other character), escape another&rsquo;s grapple, retrieve a
spell
component, pin another character, or break another&rsquo;s pin while
you are
pinning an opponent.</p>
    <a id="if-youre-pinned-by-an-opponent"></a><h6>If You&rsquo;re Pinned by an Opponent</h6>
    <p className="initial">When an opponent has pinned you, you are held
immobile (but not helpless) for 1 round. While you&rsquo;re pinned, you
take
a &ndash;4 penalty to your AC against opponents other than the one
pinning
you. At your opponent&rsquo;s option, you may also be unable to speak.
On
your turn, you can try to escape the pin by making an opposed grapple
check in place of an attack. You can make an Escape Artist check in
place of your grapple check if you want, but this requires a standard
action. If you win, you escape the pin, but you&rsquo;re still
grappling.</p>
    <a id="joining-a-grapple"></a><h6>Joining a Grapple</h6>
    <p className="initial">If your target is already grappling someone
else, you can use an attack to start a grapple, as above, except that
the target doesn&rsquo;t get an attack of opportunity against you, and
your
grab automatically succeeds. You still have to make a successful
opposed grapple check to become part of the grapple.</p>
    <p>If there are multiple opponents involved in the grapple, you
pick one to make the opposed grapple check against.</p>
    <a id="multiple-grapplers"></a><h6>Multiple Grapplers</h6>
    <p className="initial">Several combatants can be in a single grapple.
Up to four combatants can grapple a single opponent in a given round.
Creatures that are one or more size categories smaller than you count
for half, creatures that are one size category larger than you count
double, and creatures two or more size categories larger count
quadruple.</p>
    <p>When you are grappling with multiple opponents, you choose one
opponent to make an opposed check against. The exception is an attempt
to escape from the grapple; to successfully escape, your grapple check
must beat the check results of each opponent.</p>
    <a id="mounted-combat"></a><h5>MOUNTED COMBAT</h5>
    <a id="horses-in-combat"></a><p className="initial"><b>Horses in Combat</b>: Warhorses and
warponies can serve readily as combat steeds. Light horses, ponies, and
heavy horses, however, are frightened by combat. If you don&rsquo;t
dismount,
you must make a DC 20 Ride check each round as a move action to control
such a horse. If you succeed, you can perform a standard action after
the move action. If you fail, the move action becomes a full round
action and you can&rsquo;t do anything else until your next turn.</p>
    <p>Your mount acts on your initiative count as you direct it. You
move at its speed, but the mount uses its action to move.</p>
    <p>A horse (not a pony) is a Large creature and thus takes up a
space 10 feet (2 squares) across. For simplicity, assume that you share
your mount&rsquo;s space during combat.</p>
    <a id="combat-while-mounted"></a><p><b>Combat while Mounted</b>: With a DC 5 Ride check, you can
guide your mount with your knees so as to use both hands to attack or
defend yourself. This is a free action.</p>
    <p>When you attack a creature smaller than your mount that is on
foot, you get the +1 bonus on melee attacks for being on higher ground.
If your mount moves more than 5 feet, you can only make a single melee
attack. Essentially, you have to wait until the mount gets to your
enemy before attacking, so you can&rsquo;t make a full attack. Even at
your
mount&rsquo;s full speed, you don&rsquo;t take any penalty on melee
attacks while
mounted.</p>
    <p>If your mount charges, you also take the AC penalty associated
with a charge. If you make an attack at the end of the charge, you
receive the bonus gained from the charge. When charging on horseback,
you deal double damage with a lance (see <a href="#charge">Charge</a>).</p>
    <p>You can use ranged weapons while your mount is taking a double
move, but at a &ndash;4 penalty on the attack roll. You can use ranged
weapons while your mount is running (quadruple speed), at a &ndash;8
penalty.
In either case, you make the attack roll when your mount has completed
half its movement. You can make a full attack with a ranged weapon
while your mount is moving. Likewise, you can take move actions normally</p>
    <a id="casting-spells-while-mounted"></a><p><b>Casting Spells while Mounted</b>: You can cast a spell
normally if your mount moves up to a normal move (its speed) either
before or after you cast. If you have your mount move both before and
after you cast a spell, then you&rsquo;re casting the spell while the
mount
is moving, and you have to make a Concentration check due to the
vigorous motion (DC 10 + spell level) or lose the spell. If the mount
is running (quadruple speed), you can cast a spell when your mount has
moved up to twice its speed, but your Concentration check is more
difficult due to the violent motion (DC 15 + spell level).</p>
    <a id="if-your-mount-falls-in-battle"></a><p><b>If Your Mount Falls in Battle</b>: If your mount falls, you
have to succeed on a DC 15 Ride check to make a soft fall and take no
damage. If the check fails, you take 1d6 points of damage.</p>
    <a id="if-you-are-dropped"></a><p><b>If You Are Dropped</b>: If you are knocked unconscious, you
have a 50% chance to stay in the saddle (or 75% if you&rsquo;re in a
military
saddle). Otherwise you fall and take 1d6 points of damage. Without you
to guide it, your mount avoids combat.</p>
    <h5><a id="overrun">OVERRUN</a></h5>
    <p className="initial">You can attempt an overrun as a standard
action taken during your move. (In general, you
cannot take a standard action during a move; this is an exception.)
With an overrun, you attempt to plow past or over your opponent (and
move through his square) as you move. You can only overrun an opponent
who is one size category larger than you, the same size, or smaller.
You can make only one overrun attempt per round.</p>
    <p>If you&rsquo;re attempting to overrun an opponent, follow
these
steps.</p>
    <a id="overrun-attack-op-opportunity"></a><p><b>Step 1: Attack of Opportunity</b>. Since you begin the
overrun by moving into the defender&rsquo;s space, you provoke an
attack of
opportunity from the defender.</p>
    <a id="overrun-opponent-avoids"></a><p><b>Step 2: Opponent Avoids</b>? The defender has the option to
simply avoid you. If he avoids you, he doesn&rsquo;t suffer any ill
effect and you may keep moving (You can always move through a square
occupied by someone who lets you by.) The overrun attempt doesn&rsquo;t
count against your actions this round (except for any movement required
to enter the opponent&rsquo;s square). If your opponent doesn&rsquo;t
avoid you, move to Step 3.</p>
    <a id="overrun-opponent-blocks"></a><p><b>Step 3: Opponent Blocks</b>? If your opponent blocks you,
make a Strength check opposed by the defender&rsquo;s Dexterity or
Strength
check (whichever ability score has the higher modifier). A combatant
gets a +4 bonus on the check for every size category he is larger than
Medium or a &ndash;4 penalty for every size category he is smaller than
Medium. The defender gets a +4 bonus on his check
if he has more than two legs or is otherwise more stable than a normal
humanoid. If you win, you knock the defender prone. If you lose, the
defender may immediately react and make a Strength check opposed by
your Dexterity or Strength check (including the size modifiers noted
above, but no other modifiers) to try to knock you prone.</p>
    <a id="overrun-consequences"></a><p><b>Step 4: Consequences</b>. If you succeed in knocking your
opponent prone, you can continue your movement as normal. If you fail
and are knocked prone in turn, you have to move 5 feet back the way you
came and fall prone, ending your movement there. If you fail but are
not knocked prone, you have to move 5 feet back the way you came,
ending your movement there. If that square is occupied, you fall prone
in that square.</p>
    <a id="improved-overrun"></a><p><b>Improved Overrun</b>: If you have the Improved Overrun
feat, your target may not choose to avoid you.</p>
    <a id="mounted-overrun-trample"></a><p><b>Mounted Overrun (Trample)</b>: If you attempt an overrun
while mounted, your mount makes the Strength check to determine the
success or failure of the overrun attack (and applies its size
modifier, rather than yours). If you have the Trample feat and attempt
an overrun while mounted, your target may not choose to avoid you, and
if you knock your opponent prone with the overrun, your mount may make
one hoof attack against your opponent.</p>
    <h5><a id="sunder"></a>SUNDER</h5>
    <p className="initial">You can use a melee attack with a slashing or
bludgeoning weapon to strike a weapon or shield that your opponent is
holding. If you&rsquo;re attempting to sunder a weapon or shield,
follow the
steps outlined here. (Attacking held objects other than weapons or
shields is covered below.)</p>
    <a id="table-common-armor-weapon-and-shield-hardness-and-hit-points"></a><p><b>Table: Common Armor, Weapon, and Shield Hardness and Hit
Points</b></p>
    <table border="0" cellPadding="1" cellSpacing="0" style={{
      "width": "450px"
    }}>
      <tbody>
        <tr>
          <th>Weapon or Shield</th>
          <th>Hardness</th>
          <th>HP<sup>1</sup></th>
        </tr>
        <tr className="odd-row">
          <td>Light blade</td>
          <td>10</td>
          <td>2</td>
        </tr>
        <tr>
          <td>One-handed blade</td>
          <td>10</td>
          <td>5</td>
        </tr>
        <tr className="odd-row">
          <td>Two-handed blade</td>
          <td>10</td>
          <td>10</td>
        </tr>
        <tr>
          <td>Light metal-hafted weapon</td>
          <td>10</td>
          <td>10</td>
        </tr>
        <tr className="odd-row">
          <td>One-handed metal-hafted weapon</td>
          <td>10</td>
          <td>20</td>
        </tr>
        <tr>
          <td>Light hafted weapon</td>
          <td>5</td>
          <td>2</td>
        </tr>
        <tr className="odd-row">
          <td>One-handed hafted weapon</td>
          <td>5</td>
          <td>5</td>
        </tr>
        <tr>
          <td>Two-handed hafted weapon</td>
          <td>5</td>
          <td>10</td>
        </tr>
        <tr className="odd-row">
          <td>Projectile weapon</td>
          <td>5</td>
          <td>5</td>
        </tr>
        <tr>
          <td>Armor</td>
          <td>special<sup>2</sup></td>
          <td>armor bonus x 5</td>
        </tr>
        <tr className="odd-row">
          <td>Buckler</td>
          <td>10</td>
          <td>5</td>
        </tr>
        <tr>
          <td>Light wooden shield</td>
          <td>5</td>
          <td>7</td>
        </tr>
        <tr className="odd-row">
          <td>Heavy wooden shield</td>
          <td>5</td>
          <td>15</td>
        </tr>
        <tr>
          <td>Light steel shield</td>
          <td>10</td>
          <td>10</td>
        </tr>
        <tr className="odd-row">
          <td>Heavy steel shield</td>
          <td>10</td>
          <td>20</td>
        </tr>
        <tr>
          <td className="last-row">Tower shield</td>
          <td className="last-row">5</td>
          <td className="last-row">20</td>
        </tr>
        <tr>
          <td colSpan="3">1 <i>The hp value given is for Medium
armor, weapons, and shields. Divide by 2 for each size category of the
item smaller than Medium, or multiply it by 2 for each size category
larger than Medium.</i></td>
        </tr>
        <tr>
          <td colSpan="3">2 <i>Varies by material.</i></td>
        </tr>
      </tbody>
    </table>
    <a id="sunder-attack-of-opportunity"></a><p><b>Step 1: Attack of Opportunity</b>. You provoke an attack of
opportunity from the target whose weapon or shield you are trying to
sunder. (If you have the Improved Sunder feat, you don&rsquo;t incur an
attack of opportunity for making the attempt.)</p>
    <a id="sunder-opposed-rolls"></a><p><b>Step 2: Opposed Rolls</b>. You and the defender make
opposed attack rolls with your respective weapons. The wielder of a
two-handed weapon on a sunder attempt gets a +4 bonus on this roll, and
the wielder of a light weapon takes a &ndash;4 penalty. If the
combatants are
of different sizes, the larger combatant gets a bonus on the attack
roll of +4 per difference in size category.</p>
    <a id="sunder-consequences"></a><p><b>Step 3: Consequences</b>. If you beat the defender, roll
damage and deal it to the weapon or shield. See Table: Common Armor,
Weapon, and Shield Hardness and Hit Points to determine how much damage
you must deal to destroy the weapon or shield.</p>
    <p>If you fail the sunder attempt, you don&rsquo;t deal any
damage.</p>
    <a id="sundering-a-carried-or-worn-object"></a><p><i>Sundering a Carried or Worn Object</i>: You don&rsquo;t use
an
opposed attack roll to damage a carried or worn object. Instead, just
make an attack roll against the object&rsquo;s AC. A carried or worn
object&rsquo;s
AC is equal to 10 + its size modifier + the Dexterity modifier of the
carrying or wearing character. Attacking a carried or worn object
provokes an attack of opportunity just as attacking a held object does.
To attempt to snatch away an item worn by a defender rather than damage
it, see <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="#disarm">Disarm</a>.
You can&rsquo;t sunder armor worn by
another character.</p>
    <a id="throw-splash-weapon"></a><h5><a id="throw"></a>THROW SPLASH WEAPON</h5>
    <p className="initial">A splash weapon is a ranged weapon that breaks
on impact, splashing or scattering its contents over its target and
nearby creatures or objects. To attack with a splash weapon, make a
ranged touch attack against the target. Thrown weapons require no
weapon proficiency, so you don&rsquo;t take the &ndash;4 nonproficiency
penalty. A
hit deals direct hit damage to the target, and splash damage to all
creatures within 5 feet of the target.</p>
    <p>You can instead target a specific grid intersection. Treat
this as a ranged attack against AC 5. However, if you target a grid
intersection, creatures in all adjacent squares are dealt the splash
damage, and the direct hit damage is not dealt to any creature. (You
can&rsquo;t target a grid intersection occupied by a creature, such as
a
Large or larger creature; in this case, you&rsquo;re aiming at the
creature.)</p>
    <p>If you miss the target (whether aiming at a creature or a grid
intersection), roll 1d8. This determines the misdirection of the throw,
with 1 being straight back at you and 2 through 8 counting clockwise
around the grid intersection or target creature. Then, count a number
of squares in the indicated direction equal to the range increment of
the throw.</p>
    <p>After you determine where the weapon landed, it deals splash
damage to all creatures in adjacent squares.</p>
    <h5><a id="trip"></a>TRIP</h5>
    <p className="initial">You can try to trip an opponent as an unarmed
melee attack. You can only trip an opponent who is one size category
larger than you, the same size, or smaller.</p>
    <a id="making-a-trip-attack"></a><p><b>Making a Trip Attack</b>: Make an unarmed melee touch
attack against your target. This provokes an attack of opportunity from
your target as normal for unarmed attacks.</p>
    <p>If your attack succeeds, make a Strength check opposed by the
defender&rsquo;s Dexterity or Strength check (whichever ability score
has the
higher modifier). A combatant gets a +4 bonus for every size category
he is larger than Medium or a &ndash;4 penalty for every size category
he is
smaller than Medium. The defender gets a +4 bonus on his check if he
has more than two legs or is otherwise more stable than a normal
humanoid. If you win, you trip the defender. If you lose, the defender
may immediately react and make a Strength check opposed by your
Dexterity or Strength check to try to trip you.</p>
    <a id="avoiding-attacks-of-opportunity"></a><p><i>Avoiding Attacks of Opportunity</i>: If you have the
Improved Trip feat, or if you are tripping with a weapon (see below),
you don&rsquo;t provoke an attack of opportunity for making a trip
attack.</p>
    <a id="being-tripped-prone"></a><p><b>Being Tripped (Prone)</b>: A tripped character is prone.
Standing up is a move action.</p>
    <a id="tripping-a-mounted-opponent"></a><p><b>Tripping a Mounted Opponent</b>: You may make a trip attack
against a mounted opponent. The defender may make a Ride check in place
of his Dexterity or Strength check. If you succeed, you pull the rider
from his mount.</p>
    <a id="tripping-with-a-weapon"></a><p><b>Tripping with a Weapon</b>: Some weapons can be used to
make trip attacks. In this case, you make a melee touch attack with the
weapon instead of an unarmed melee touch attack, and you don&rsquo;t
provoke
an attack of opportunity. If you are tripped during your own trip
attempt, you can drop the weapon to avoid being tripped.</p>
    <a id="turn-or-rebuke-undead"></a><h5><a id="turn"></a>TURN OR REBUKE UNDEAD</h5>
    <p className="initial">Good clerics and paladins and some neutral
clerics can channel positive energy, which can halt, drive off (rout),
or destroy undead. Evil clerics and some neutral clerics can channel
negative energy, which can halt, awe (rebuke), control (command), or
bolster undead. Regardless of the effect, the general term for the
activity is &ldquo;turning.&rdquo; When attempting to exercise their
divine control
over these creatures, characters make turning checks.</p>
    <a id="turning-checks"></a><h6>Turning Checks</h6>
    <p className="initial">Turning undead is a supernatural ability that
a character can perform as a standard action. It does not provoke
attacks of opportunity. You must present your holy symbol to turn
undead. Turning is considered an attack.</p>
    <a id="turning-times-per-day"></a><p><b>Times per Day</b>: You may attempt to turn undead a number
of times per day equal to 3 + your Charisma modifier. You can increase
this number by taking the Extra Turning feat.</p>
    <a id="turning-range"></a><p><b>Range</b>: You turn the closest turnable undead first, and
you can&rsquo;t turn undead that are more than 60 feet away or that
have
total cover relative to you. You don&rsquo;t need line of sight to a
target,
but you do need line of effect.</p>
    <a id="turning-check"></a><p><b>Turning Check</b>: The first thing you do is roll a turning
check to see how powerful an undead creature you can turn. This is a
Charisma check (1d20 + your Charisma modifier). Table: Turning Undead
gives you the Hit Dice of the most powerful undead you can affect,
relative to your level. On a given turning attempt, you can turn no
undead creature whose Hit Dice exceed the result on this table.</p>
    <a id="turning-damage"></a><p><b>Turning Damage</b>: If your roll on Table: Turning Undead
is high enough to let you turn at least some of the undead within 60
feet, roll 2d6 + your cleric level + your Charisma modifier for turning
damage. That&rsquo;s how many total Hit Dice of undead you can turn.</p>
    <p>If your Charisma score is average or low, it&rsquo;s possible
to
roll fewer Hit Dice of undead turned than indicated on Table: Turning
Undead.</p>
    <p>You may skip over already turned undead that are still within
range, so that you do not waste your turning capacity on them.</p>
    <a id="effect-and-duration-of-turning"></a><p><b>Effect and Duration of Turning</b>: Turned undead flee from
you by the best and fastest means available to them. They flee for 10
rounds (1 minute). If they cannot flee, they cower (giving any attack
rolls against them a +2 bonus). If you approach within 10 feet of them,
however, they overcome being turned and act normally. (You can stand
within 10 feet without breaking the turning effect&mdash;you just
can&rsquo;t
approach them.) You can attack them with ranged attacks (from at least
10 feet away), and others can attack them in any fashion, without
breaking the turning effect.</p>
    <a id="destroying-undead"></a><p><b>Destroying Undead</b>: If you have twice as many levels (or
more) as the undead have Hit Dice, you destroy any that you would
normally turn.</p>
    <a id="table-turning-undead"></a><p><b>Table: Turning Undead</b></p>
    <table border="0" cellPadding="1" cellSpacing="0">
      <tbody>
        <tr>
          <th style={{
            "width": "125px"
          }}>Turning Check
Result</th>
          <th>Most Powerful Undead
Affected (Maximum Hit Dice)</th>
        </tr>
        <tr className="odd-row">
          <td>0 or lower</td>
          <td>Cleric&rsquo;s level &ndash; 4</td>
        </tr>
        <tr>
          <td>1&ndash;3</td>
          <td>Cleric&rsquo;s level &ndash; 3</td>
        </tr>
        <tr className="odd-row">
          <td>4&ndash;6</td>
          <td>Cleric&rsquo;s level &ndash; 2</td>
        </tr>
        <tr>
          <td>7&ndash;9</td>
          <td>Cleric&rsquo;s level &ndash; 1</td>
        </tr>
        <tr className="odd-row">
          <td>10&ndash;12</td>
          <td>Cleric&rsquo;s level</td>
        </tr>
        <tr>
          <td>13&ndash;15</td>
          <td>Cleric&rsquo;s level + 1</td>
        </tr>
        <tr className="odd-row">
          <td>16&ndash;18</td>
          <td>Cleric&rsquo;s level + 2</td>
        </tr>
        <tr>
          <td>19&ndash;21</td>
          <td>Cleric&rsquo;s level + 3</td>
        </tr>
        <tr className="odd-row">
          <td>22 or higher</td>
          <td>Cleric&rsquo;s level + 4</td>
        </tr>
      </tbody>
    </table>
    <a id="evil-clerics-and-undead"></a><h6>Evil Clerics and Undead</h6>
    <p className="initial">Evil clerics channel negative energy to rebuke
(awe) or command (control) undead rather than channeling positive
energy to turn or destroy them. An evil cleric makes the equivalent of
a turning check. Undead that would be turned are rebuked instead, and
those that would be destroyed are commanded.</p>
    <a id="rebuked"></a><p><b>Rebuked</b>: A rebuked undead creature cowers as if in awe
(attack rolls against the creature get a +2 bonus). The effect lasts 10
rounds.</p>
    <a id="commanded"></a><p><b>Commanded</b>: A commanded undead creature is under the
mental control of the evil cleric. The cleric must take a standard
action to give mental orders to a commanded undead. At any one time,
the cleric may command any number of undead whose total Hit Dice do not
exceed his level. He may voluntarily relinquish command on any
commanded undead creature or creatures in order to command new ones.</p>
    <a id="dispelling-turning"></a><p><b>Dispelling Turning</b>: An evil cleric may channel negative
energy to dispel a good cleric&rsquo;s turning effect. The evil cleric
makes
a turning check as if attempting to rebuke the undead. If the turning
check result is equal to or greater than the turning check result that
the good cleric scored when turning the undead, then the undead are no
longer turned. The evil cleric rolls turning damage of 2d6 + cleric
level + Charisma modifier to see how many Hit Dice worth of undead he
can affect in this way (as if he were rebuking them).</p>
    <a id="bolstering-undead"></a><p><b>Bolstering Undead</b>: An evil cleric may also bolster
undead creatures against turning in advance. He makes a turning check
as if attempting to rebuke the undead, but the Hit Dice result on
Table: Turning Undead becomes the undead creatures&rsquo; effective Hit
Dice
as far as turning is concerned (provided the result is higher than the
creatures&rsquo; actual Hit Dice). The bolstering lasts 10 rounds. An
evil
undead cleric can bolster himself in this manner.</p>
    <a id="neutral-clerics-and-undead"></a><h6>Neutral Clerics and Undead</h6>
    <p className="initial">A cleric of neutral alignment can either turn
undead but not rebuke them, or rebuke undead but not turn them. See
Turn or Rebuke Undead for more information.</p>
    <p>Even if a cleric is neutral, channeling positive energy is a
good act and channeling negative energy is evil.</p>
    <a id="paladins-and-undead"></a><h6>Paladins and Undead</h6>
    <p className="initial">Beginning at 4th level, paladins can turn
undead as if they were clerics of three levels lower than they actually
are.</p>
    <a id="turning-other-creatures"></a><h6>Turning Other Creatures</h6>
    <p className="initial">Some clerics have the ability to turn
creatures other than undead. The turning check result is determined as
normal.</p>
    <h5><a id="two-weapon-fighting"></a>TWO-WEAPON FIGHTING</h5>
    <p className="initial">If you wield a second weapon in your off hand,
you can get one extra attack per round with that weapon. You suffer a
&ndash;6 penalty with your regular attack or attacks with your primary
hand
and a &ndash;10 penalty to the attack with your off hand when you fight
this
way. You can reduce these penalties in two ways: </p>
    <ul>
      <li>
        <p>If your off-hand weapon is light, the penalties are
reduced by 2 each. (An unarmed strike is always considered light.)</p>
      </li>
      <li>
        <p>The Two-Weapon Fighting feat lessens the primary hand
penalty by 2, and the off-hand penalty by 6.</p>
        <p>Table: Two-Weapon Fighting Penalties summarizes the
interaction of all these factors.</p>
      </li>
    </ul>
    <a id="table-two-weapon-fighting-penalties"></a><p><b>Table: Two-Weapon Fighting Penalties</b></p>
    <table border="0" cellPadding="1" cellSpacing="0">
      <tbody>
        <tr valign="bottom">
          <th style={{
            "width": "200px"
          }}>Circumstances</th>
          <th style={{
            "width": "75px"
          }}>Primary
Hand</th>
          <th style={{
            "width": "75px"
          }}>Off Hand</th>
        </tr>
        <tr className="odd-row">
          <td>Normal penalties</td>
          <td>&ndash;6</td>
          <td>&ndash;10</td>
        </tr>
        <tr>
          <td>Off-hand weapon is light</td>
          <td>&ndash;4</td>
          <td>&ndash;8</td>
        </tr>
        <tr className="odd-row">
          <td>Two-Weapon Fighting feat</td>
          <td>&ndash;4</td>
          <td>&ndash;4</td>
        </tr>
        <tr>
          <td className="last-row">Off-hand weapon is light and
Two-Weapon Fighting feat</td>
          <td className="last-row">&ndash;2</td>
          <td className="last-row">&ndash;2</td>
        </tr>
      </tbody>
    </table>
    <a id="double-weapons"></a><p><b>Double Weapons</b>: You can use a double weapon to make an
extra attack with the off-hand end of the weapon as if you were
fighting with two weapons. The penalties apply as if the off-hand end
of the weapon were a light weapon.</p>
    <a id="thrown-weapons"></a><p><b>Thrown Weapons</b>: The same rules apply when you throw a
weapon from each hand. Treat a dart or shuriken as a light weapon when
used in this manner, and treat a bolas, javelin, net, or sling as a
one-handed weapon.</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      